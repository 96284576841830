@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .card {
    @apply rounded-lg bg-white p-4;
  }

  .card-sm {
    @apply rounded-lg bg-white p-3;
  }

  .divider {
    @apply my-4 border border-gray-200;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    @apply appearance-none;
  }

  .scrollbar-hidden::-webkit-scrollbar {
    @apply hidden;
  }
  .scrollbar::-webkit-scrollbar {
    @apply block;
  }
  .scrollbar-hidden {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@layer components {
  .score-summary-table {
    table-layout: fixed;
    border-spacing: 0; /* Removes the cell spacing via CSS */
    border-collapse: collapse;
  }

  .score-summary-table thead tr th {
    @apply border-l border-gray-100 py-4 !text-center text-gray-500;
  }

  .score-summary-table thead tr th:first-child {
    @apply border-l-0;
  }

  .score-summary-table tbody tr td {
    @apply border-l border-t border-gray-100;
  }

  .score-summary-table tbody tr td:first-child {
    @apply border-l-0;
  }

  .score-summary-table thead tr th span {
    @apply block w-full text-center text-base;
  }

  .score-summary-table tbody tr td {
    @apply py-3 text-center text-base;
  }

  .score-summary-table tfoot {
    @apply bg-teal-600 text-white;
  }

  .score-summary-table tfoot td {
    @apply border border-l-white py-4  text-center text-base;
  }

  .score-summary-table tfoot td:first-child {
    @apply border-l-teal-600 font-bold;
  }

  .score-summary-table tfoot td:last-child {
    @apply border-r-teal-600 font-bold;
  }

  input[type="file"]::file-selector-button {
    @apply h-10 w-full rounded-lg border-none bg-blue-100 text-center font-bold;
  }
}

@layer base {
  ::-webkit-scrollbar {
    @apply w-2;
  }
  ::-webkit-scrollbar-track {
    @apply bg-gray-100;
  }

  ::-webkit-scrollbar-thumb {
    @apply bg-gray-300 bg-opacity-75 transition-all duration-300;
  }

  ::-webkit-scrollbar-thumb:hover {
    @apply bg-opacity-100;
  }

  tr[type="button"] {
    @apply appearance-none border-none bg-transparent;
  }
  :root {
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
  }
}

/* mobile viewport bug fix */

* {
  padding: 0;
  margin: 0;
}

.scrollable {
  padding: env(safe-area-inset-top, 0) 0
    calc(env(safe-area-inset-bottom, 0) + 12px) 0;
  height: -moz-available; /* WebKit-based browsers will ignore this. */
  height: -webkit-stretch; /* Mozilla-based browsers will ignore this. */
  height: stretch;
  -webkit-overflow-scrolling: touch;
}
html {
  @apply bg-gradient-to-br from-neutral-300 via-white to-neutral-300;
}

html,
body,
#__next,
main {
  height: -moz-available; /* WebKit-based browsers will ignore this. */
  height: -webkit-stretch; /* Mozilla-based browsers will ignore this. */
  height: stretch;
}

h1 {
  @apply text-3xl font-bold;
}

h2 {
  @apply text-2xl font-bold;
}

h3 {
  @apply font-bold;
}

h4 {
  @apply font-medium;
}
div {
  @apply text-black;
}

span,
a,
p,
li {
  @apply text-black;
  @apply font-medium;
}

a {
  @apply text-blue-700;
}

.react-datepicker-drop-down .react-datepicker {
  @apply border border-gray-100;
}
.react-datepicker-drop-down .react-datepicker-wrapper {
  @apply w-full;
}
.react-datepicker-drop-down .react-datepicker__tab-loop {
  @apply mx-4 w-full;
}
.react-datepicker-drop-down .react-datepicker-popper {
  @apply w-full px-4;
}
.react-datepicker-drop-down .react-datepicker__triangle {
  @apply hidden;
}
.react-datepicker-drop-down .react-datepicker__navigation {
  @apply top-2;
}

.react-datepicker {
  @apply w-full border-none;
}
.react-datepicker__header {
  @apply !border-none !bg-white;
}
.react-datepicker__month {
  @apply !m-0;
}
.react-datepicker__day-names {
  @apply mt-3 flex w-full !text-gray-600;
}
.react-datepicker__day--today {
  @apply relative after:absolute after:bottom-1 after:h-1 after:w-4 after:bg-gray-900 after:content-[''];
}
.react-datepicker__day--selected {
  @apply !bg-yellow !text-black;
}
.react-datepicker__day--keyboard-selected {
  @apply !border-none !bg-white;
}
.react-datepicker__day-name {
  @apply !w-full text-base;
}
.react-datepicker__month-container {
  @apply w-full;
}
.react-datepicker__week {
  @apply !flex;
}
.react-datepicker__day {
  @apply m-0 !flex h-10 !w-full items-center justify-center text-base;
}
.react-datepicker__day--outside-month {
  @apply !text-gray-500;
}
.react-datepicker__day--disabled {
  @apply !text-gray-400;
}
.container {
  @apply w-screen;
}

model-viewer {
  @apply h-[400px] w-screen;
}

.leaderboards thead tr th:first-child span {
  padding-right: 0.5rem;
}

.svg-inline--fa {
  @apply h-full;
}

.custom-select-box:disabled span:first-child {
  @apply text-gray-400;
}
